import * as React from 'react';
import { Box } from '@mui/material';
import { RecommendContentSP } from './HomeContentSPParts/RecommendContentSP';
import { NavLinkContentSP } from './HomeContentSPParts/NavLinkContentSP';
import { LifeHelpContentSP } from './HomeContentSPParts/LifeHelpContentSP';
import { EstateBasicContentSP } from './HomeContentSPParts/EstateBasicContentSP';
import { EstateHelpContentSP } from './HomeContentSPParts/EstateHelpContentSP';
import { AreaInfoContentSP } from './HomeContentSPParts/AreaInfoContentSP';
import { TrendContentSP } from './HomeContentSPParts/TrendContentSP';
import { AboutOrganizationContentSP } from './HomeContentSPParts/AboutOrganizationContentSP';
import { AssociationContentSP } from './HomeContentSPParts/AssociationContentSP';
import { NotificationSP } from './HomeContentSPParts/NotificationSP';
import { RealEstateInformation } from '@/lib/swagger';

export const HomeContentSP: React.VFC<{ informations: RealEstateInformation[] }> = (props) => {
  const hasInformations = props.informations.length ? true : false;

  return (
    <Box width="100%" sx={{ display: { xs: 'block', sm: 'none' } }}>
      {hasInformations && <NotificationSP informations={props.informations} />}
      <NavLinkContentSP />
      <RecommendContentSP />

      <Box>
        <LifeHelpContentSP />
        <EstateBasicContentSP />
        <EstateHelpContentSP />
        <AreaInfoContentSP />
        <TrendContentSP />
        <AboutOrganizationContentSP />
        <AssociationContentSP />
      </Box>
    </Box>
  );
};
