import * as React from 'react';
import type { GetServerSideProps, NextPage } from 'next';
import { Box } from '@mui/material';
import { Sidebar } from '../features/layout/Sidebar';
import { HomeContentPC } from '../features/home/HomeContentPC';
import { HomeContentSP } from '../features/home/HomeContentSP';
import Head from 'next/head';
import { swaggerClient } from '@/lib/swaggerClient';
import { RealEstateInformation } from '@/lib/swagger';

const Home: NextPage<{ informations: RealEstateInformation[] }> = (props) => {
  return (
    <div>
      <Head>
        <title>
          不動産を探すなら【ラビーネット不動産】全日本不動産協会が運営する物件情報検索サイト
        </title>
        <meta
          name="description"
          content="不動産を探すなら【ラビーネット不動産】日本全国の賃貸、購入物件情報を検索することができます。全日本不動産協会が運営する安心・安全な不動産総合サイトです。不動産の基礎知識、お役立ち情報なども盛りだくさん。ラビーネット不動産なら、あなたにぴったりの物件がきっと見つかります。"
        ></meta>
      </Head>
      <Box
        maxWidth="md"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: '500px',
          width: '100%',
          py: [0, 5],
        }}
      >
        <HomeContentPC informations={props.informations} />
        <HomeContentSP informations={props.informations} />
        <Sidebar />
      </Box>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  // [ ] #issue 182 catchのエラーハンドリング
  const informations: RealEstateInformation[] = [];
  try {
    const infoResult = await swaggerClient.api.informationList();
    informations.push(...infoResult);
  } catch (e) {
    console.log(e);
    informations.push({
      title: 'infomation api request error',
      url: '',
      date: new Date().toString(),
    });
  }

  return { props: { informations } };
};

// eslint-disable-next-line import/no-default-export
export default Home;
