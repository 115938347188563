import * as React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SearchByPrefectureMap } from '../search/SearchByPrefectureMap';
import { RecommendContentPC } from './HomeContentPCParts/RecommendContentPC';
import { LifeHelpContentPC } from './HomeContentPCParts/LifeHelpContentPC';
import { EstateBasicContentPC } from './HomeContentPCParts/EstateBasicContentPC';
import { EstateHelpContentPC } from './HomeContentPCParts/EstateHelpContentPC';
import { AssociationContentPC } from './HomeContentPCParts/AssociationContentPC';
import { NextLink } from '@/components/link/NextLink';
import { format } from 'date-fns';
import { RealEstateInformation } from '@/lib/swagger';

export const HomeContentPC: React.VFC<{ informations: RealEstateInformation[] }> = (props) => {
  const hasInformations = props.informations.length ? true : false;
  const informationListItems = props.informations.map((info, index) => {
    const date = format(new Date(info.date), 'yyyy/MM/dd');
    return (
      <ListItem sx={{ alignItems: 'center', pl: 0 }} key={index}>
        <ListItemIcon sx={{ height: '24px', minWidth: '24px' }}>
          <ArrowRightIcon sx={{ color: 'accent.main' }} />
        </ListItemIcon>
        <ListItemText sx={{ m: 0 }} key={index}>
          {date + ' '}
          <NextLink href={info.url || '#'} target="_blank" sx={{ textDecoration: 'underline' }}>
            {info.title}
          </NextLink>
        </ListItemText>
      </ListItem>
    );
  });

  return (
    <Box maxWidth="780px" width="100%" px={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
      {hasInformations && (
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="h3"
            sx={{
              fontSize: '1em',
              pb: 0.5,
              borderBottom: '1px solid',
              borderColor: 'grey.500',
            }}
          >
            お知らせ
          </Typography>
          <List>{informationListItems}</List>
        </Box>
      )}

      <RecommendContentPC />

      <Box my={4}>
        <Typography
          variant="h3"
          sx={{
            pb: 0.5,
            borderBottom: '4px solid',
            borderColor: 'primary.light',
          }}
        >
          都道府県から探す
        </Typography>
        <SearchByPrefectureMap />
      </Box>

      <LifeHelpContentPC />
      <EstateBasicContentPC />
      <EstateHelpContentPC />
      <AssociationContentPC />
    </Box>
  );
};
